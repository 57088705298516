<template>
    <v-dialog
        :key="pageKey"
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.updateTableOfContents') }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form>
                    <vue-draggable-resizable></vue-draggable-resizable>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveTableOfContents()"
                    v-if="updateMode == true"
                >
                    {{ $t('message.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "UpdateTableOfContents",
    props: ['dialog','updateMode','update-done','pageKey'],
    data() {
        return {
            edit_dialog: false,
            loading: {
                get: false,
                save: false
            },
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapGetters('help',{
            TableOfContents: 'TableOfContents'
        }),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        saveTableOfContents () {

        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    let _this = this
                    setTimeout(function(){
                        _this.loading.get = false
                    },1000)
                }
            } else {
                if(this.updateMode == true) {
                    // this.resetGradeToUpdate()
                } else {
                    // this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        this.loading.get = false
    }
}
</script>

<style scoped>

</style>